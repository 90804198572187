.attachment-form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.attachment-form > .form-footer {
  display: flex;
  justify-content: space-between;
}

.attachments-container {
  min-height: 0px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.attachment-quantity-counters {
  display: flex;
  justify-content: space-evenly;
  height: 104px;
}

.attachment-quantity-counters > .counter {
  align-self: flex-end;
}

.attachment-quantity-counters > .optional-input {
  margin: 0;
  width: 190px;
}

.attachment-quantity-counters > .optional-input > .counter {
  margin: 10px 0;
}

.accessories-confirmation-modal > .modal-content {
  flex-direction: column;
  align-items: center;
}

.accessories-confirmation-modal p {
  margin: 0;
  font-size: 18px;
  color: #909291;
  font-weight: bold;
  text-align: center;
}

.accessories-confirmation-modal button {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  margin: 2rem 0.5rem;
}

.accessories-confirmation-modal .btn-secondary {
  background-color: transparent;
  color: var(--accent-color);
}
