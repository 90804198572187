.couplers-modal > .modal-content {
  flex-wrap: wrap;
}

.coupler {
  width: 250px;
  padding: 0.75rem;
  margin: 0 1.25rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.coupler > p {
  margin-top: 0;
  height: 42px;
}
