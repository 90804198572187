.help > .tooltip {
  border-color: transparent;
  border-radius: 0.5rem;
  width: 250px;
  color: #909291;
  padding: 0 1rem;
  z-index: 10;
}

.help > .tooltip ul {
  list-style: none;
  padding: 0;
}

.help > .tooltip li {
  line-height: 1.5rem;
}

.help > .tooltip li:before {
  content: '-';
  margin-right: 0.5rem;
  color: var(--accent-color);
}
