.contact-info-modal {
  flex-direction: column;
  padding-inline: 0;
}

.contact-info-header {
  padding-inline: 0;
}

.contact-info-modal p {
  font-size: 15px;
}

.contact-info-modal a {
  display: block;
  margin-bottom: 0.5rem;
}

.contact-sales {
  display: flex;
  flex-wrap: wrap;
}

.contact-sales > h2,
.contact-sales > p:last-child {
  flex-basis: 100%;
}

.contact-sales > h2 {
  margin-top: 1rem;
}

.contact-sales p {
  margin: 0;
}

.contact-sales > div .text-heavy {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.contact-sales > div > p:nth-child(2) {
  margin-bottom: 1rem;
}

.contact-sales > div > a:nth-child(4) {
  margin-bottom: 1rem;
  word-break: break-all;
}

.contact-sales > div > a:nth-child(5) {
  margin-bottom: 0;
}

.contact-sales > p {
  margin-top: 1rem;
  text-align: center;
}

.contact-sales > div {
  width: 20%;
  min-width: 11rem;
  padding-right: 5px;
}

.contact-info-footer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 1.25rem;
}

.contact-info-footer > hr {
  width: 100%;
}
