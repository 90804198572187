main {
  min-height: 0px;
  border: 1px solid #eeeeee;
  padding: 1rem;
  flex-grow: 1;
}

.multistep-form-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.multistep-form-nav > div {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 2;
}

.multistep-form-nav > div:first-child {
  align-items: flex-start;
  flex-grow: 1;
}

.multistep-form-nav > div:last-child {
  align-items: flex-end;
  flex-grow: 1;
}

.step,
.step--active {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  z-index: 2;
  border: 1px solid #eeeeee;
  background-color: white;
}

.progress,
.progress--active {
  position: absolute;
  top: 1.15rem;
  width: 100%;
  height: 4px;
  background-color: #eeeeee;
}

.progress--active {
  background-color: var(--accent-color);
}

.step--active {
  background-color: var(--accent-color);
  color: white;
  border-color: transparent;
}

.form-footer {
  border-top: 1px solid #909291;
  padding-top: 1rem;
  margin: 20px;
}
