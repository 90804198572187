.accordion {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom: none;
}

.accordion > .center-y {
  padding: 10px;
}

.accordion > div > svg {
  margin-right: 0.5rem;
  width: 25px;
  height: 25px;
}

.accordion-content {
  display: flex;
  flex-wrap: wrap;
}
