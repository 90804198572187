.subproject-modal {
  position: relative;
  z-index: 10000;
  min-height: 700px;
}

.subproject-modal > .modal-content {
  flex-direction: column;
  margin-inline: 1rem;
}

.configuration-checkbox {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}

.configuration-checkbox > span {
  margin-right: 10px;
}
