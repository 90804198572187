.pendant-info-modal > .modal-content {
  flex-direction: column;
}

.pendant-info-modal .modal-header {
  margin-bottom: 0;
}

.pendant-info-modal .note {
  margin: 0;
  color: var(--accent-color);
  font-size: 13px;
}

.pendant-info {
  display: flex;
  align-items: center;
  margin: 0.75rem 0 1.5rem 0;
}

.pendant-info img {
  height: 75px;
  margin-right: 1rem;
}

.pendant-configs {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.config-name {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #393b3a;
  margin-top: 0.5rem;
}

.configuration-info {
  display: flex;
  align-items: center;
}

.configuration-info .pendant-info {
  margin-top: 0;
  margin-bottom: 0;
  flex-grow: 1;
}

.configuration-info img {
  height: 40px;
  margin-left: 0.75rem;
}
