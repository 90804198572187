.overview {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.overview > div:first-of-type {
  height: 100%;
}

.overview > .form-footer {
  display: flex;
  justify-content: space-between;
}

.overview h1 {
  font-weight: 500;
  color: #393b3a;
  margin: 10px 0;
}

.overview > div:first-of-type {
  min-height: 0px;
  overflow-y: auto;
}

.overview > div:first-of-type button {
  background-color: var(--accent-color);
  border-color: inherit;
  padding-top: 11px;
  padding-bottom: 11px;
  margin: 0 5px;
  display: inline-flex;
}

.overview button > svg {
  fill: white;
  margin-right: 1rem;
  height: 1.25rem;
}

.project-details {
  margin: 0.5rem 0;
}

.project-details > span {
  font-size: 22px;
  margin: 15px;
}

.config-overview {
  border: 1px solid #eeeeee;
}

.config-overview > div {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;
}

.config-overview > div > strong {
  flex-basis: 100%;
  margin-bottom: 12px;
}

.config-actions > span {
  color: var(--accent-color);
  font-weight: 600;
  padding: 8.5px 34px;
  margin: 0 5px;
  cursor: pointer;
}

.config-actions > span > svg {
  fill: var(--accent-color);
  margin-right: 0.5rem;
}

.material-overview {
  background-color: #f9e4f2;
}

.material-overview > div {
  margin-right: 1rem;
}

.material-overview .text-secondary {
  height: fit-content;
  margin-bottom: 0.5rem;
}

.products-overview > table {
  flex-basis: 100%;
}

.products-overview th {
  font-size: 16px;
  font-weight: 500;
  color: #909291;
  line-height: 24px;
  text-align: left;
  padding: 0.5rem;
}

.products-overview td {
  padding: 0.5rem;
}

.products-overview tfoot td {
  font-weight: bold;
}

.products-overview tbody td:last-of-type {
  color: var(--accent-color);
  cursor: pointer;
}

.products-overview tr {
  height: 85px;
}

.products-overview thead > tr {
  height: fit-content;
}

.products-overview tr > td {
  color: #888889;
}

.config-overview-container > h2 {
  margin: 1.5rem 0 1rem 0.5rem;
}

.products-overview th,
.products-overview td {
  border-bottom: 1px solid #eeeeee;
  border-collapse: collapse;
}

.products-overview tfoot td {
  border: none;
}

.products-overview .tooltip {
  background-color: var(--accent-color);
  color: white;
  border-color: var(--accent-color);
  border-radius: 0.5rem;
  width: 120px;
  height: fit-content;
  padding: 0.5rem 0;
  font-size: 1rem;
  text-align: center;
  line-height: 1.25rem;
}

.recommended-products-container > .recommended-products-toggle {
  background-color: var(--accent-color);
  text-align: center;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  cursor: pointer;
}

.recommended-products-toggle > strong {
  color: white;
}

.recommended-product {
  display: flex;
  padding: 1rem;
}

.recommended-product > img {
  object-fit: contain;
  margin-right: 1rem;
}

.recommended-product .text-secondary {
  height: fit-content;
  margin-bottom: 0.5rem;
}

.recommended-product .product-name {
  margin-bottom: 12px;
}

.recommended-product button {
  width: 100%;
  text-align: right;
  color: var(--accent-color);
}

.material-overview .text-secondary {
  min-height: 0;
}

@media screen and (max-width: 991px) {
  .project-details {
    display: flex;
    flex-direction: column;
    margin: 0px;
  }

  .my-projects-btn {
    visibility: hidden;
    pointer-events: none;
  }

  .exports-container > button {
    font-size: 0;
  }

  .exports-container > button > svg {
    margin: 0;
  }

  .config-actions {
    flex-direction: column;
    align-items: flex-start;
  }

  table
    > thead
    > tr
    > th:not(:nth-of-type(2), :nth-of-type(4), :nth-of-type(7)) {
    display: none;
  }
  table
    > tbody
    > tr
    > td:not(:nth-of-type(2), :nth-of-type(4), :nth-of-type(7)) {
    display: none;
  }
  table
    > tfoot
    > tr
    > td:not(:nth-of-type(1), :nth-of-type(6), :nth-of-type(7)) {
    display: none;
  }
}
