input[type='checkbox'] {
  display: none;
  pointer-events: none;
}

.checkbox:hover {
  background-color: lightgray;
}

.checkbox,
.checkbox--checked {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  background: #eee;
  cursor: pointer;
}

.checkbox--checked > svg {
  margin-inline: 0.25rem !important;
  fill: white;
}

.checkbox--checked {
  background: var(--accent-color);
}
