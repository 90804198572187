.shape-form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.shape-form > div:nth-child(2) {
  flex-grow: 1;
}

.shape-form > div:nth-child(3) {
  border-top: 1px solid #909291;
  padding-top: 1rem;
  margin: 20px;
}

.shapes-container {
  min-height: 0px;
  overflow-y: auto;
}

.shape-category > p {
  display: flex;
  align-items: center;
  height: 2rem;
  margin: 0.25rem 0;
}

.shape-category > p > img {
  width: 30px;
  height: 30px;
  margin-right: 0.25rem;
}

.shape-category-products {
  margin-left: 0.5rem;
  display: flex;
  flex-wrap: wrap;
}

.shape-category-products > div:not(.modal-overlay) {
  width: 250px;
  margin: 0 1.25rem;
  padding: 0.5rem;
  cursor: pointer;
}

.shape-category-products .text-secondary {
  height: 1rem;
  text-transform: capitalize;
}

.product-name {
  margin: 0;
  color: #393b3a;
  font-weight: 500;
}
