.new-project-modal section {
  flex-basis: 50%;
  padding: 1rem;
}

.new-project-modal p {
  margin: 0;
}

.new-project-modal strong {
  display: block;
  margin-bottom: 0.75rem;
}

.new-project-modal button {
  width: 100%;
}

.new-project-modal section:last-child {
  display: flex;
  flex-direction: column;
}

.new-project-modal section:last-child > div {
  flex-grow: 1;
  margin-bottom: 15px;
}

.new-project-modal section:last-child > div > button {
  width: fit-content;
}

.existing-project-modal > .modal-footer {
  margin-top: 1.25rem;
  padding-inline: 1rem;
}

.existing-project-modal label {
  margin-bottom: 1rem;
}

.new-project-modal strong {
  display: block;
  margin-bottom: 0;
}

.new-project-modal .row {
  flex-direction: row;
}
.new-project-modal .row-reverse {
  flex-direction: row-reverse;
}

@media screen and (max-width: 992px) {
  .new-project-modal section:last-child {
    flex-basis: 30%;
  }

  .new-project-modal .row-reverse {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .new-project-modal .row {
    flex-direction: column;
  }
}
