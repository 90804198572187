.suspensions-modal .product-thumbnail,
.product-thumbnail--selected {
  width: 240px;
  min-height: 180px;
}

.suspensions-modal {
  max-height: calc(100vh - 100px);
}

.suspensions-modal > .modal-content {
  flex-wrap: wrap;
  max-height: 100%;
  overflow-y: auto;
}

.suspensions-modal .btn-secondary {
  margin-right: 0.5rem;
}

.mount-type {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  flex-basis: calc(100% / 3);
  height: 250px;
}

.mount-type span > svg {
  width: 100%;
  height: 100%;
}

.suspensions-modal > .modal-content > .additional-option {
  flex-basis: 100%;
}

.bracket-img,
.bracket-info {
  flex-basis: 50%;
}

.bracket-info > h4 {
  font-weight: 500;
}

.bracket-img > img {
  width: 100%;
}

.suspension-distances {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.suspension-distances > strong {
  flex-basis: 100%;
}

.bracket-widths {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 991px) {
  .suspensions-modal .suspension-step-2,
  .suspensions-modal .suspension-step-3 {
    flex-wrap: nowrap;
  }
}
