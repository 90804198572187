.modal-overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-container {
  background: #fff;
  width: 100%;
  max-width: 992px;
  padding: 35px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.modal-header svg {
  cursor: pointer;
}

.modal-content {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-inline: 1rem;
}

.modal-footer > hr {
  flex-basis: 100%;
}

.modal-footer > button {
  width: fit-content;
}

@media screen and (max-width: 991px) {
  .modal-container {
    min-height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .modal-content {
    flex-grow: 1;
    flex-direction: column;
  }

  .modal-footer {
    margin-bottom: 1rem;
  }
}
