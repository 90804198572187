@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
}

.App {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  color: #393b3a;
  margin: 0;
}

strong {
  color: #393b3a;
  line-height: 24px;
  font-weight: 500;
}

button {
  border-width: 0px;
  outline: none;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 500;
  border-radius: 0.25rem;
  background-color: #45cb5e;
  border: 1px solid #45cb5e;
  color: white;
  cursor: pointer;
  font-size: 17px;
}

button:disabled {
  background-color: #c0c0c1;
  border: 1px solid #c0c0c1;
}

a {
  text-decoration: none;
  color: var(--accent-color);
}

input {
  background: #fff;
  border: 1px solid #eeeeee;
  border-left: 5px solid #00b3ff;
  border-radius: 10px;
  width: 100%;
  font-size: 16px;
  padding: 20px 15px;
  outline: none;
  margin-top: 1rem;
  display: block;
}

input[type='number'] {
  width: calc(100% - 63px);
}

label {
  display: block;
  margin-bottom: 30px;
  position: relative;
}

label > div {
  position: relative;
}

.measurement-unit {
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  border: 1px solid #eeeeee;
  width: 63px;
  height: 63px;
}

.center-x {
  display: flex;
  justify-content: center;
}

.center-y {
  display: flex;
  align-items: center;
}

.center-xy {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-tertiary {
  background-color: transparent;
  color: #45cb5e;
  border-color: transparent;
}

.text-secondary {
  font-size: 14px;
  line-height: 24px;
  color: #909291;
  height: 50px;
}

.text-bold {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #393b3a;
  margin-bottom: 10px;
}

.btn-secondary {
  color: #45cb5e;
  border: 1px solid;
  background-color: transparent;
  font-weight: 500;
}

.text-accent {
  color: var(--accent-color);
}

.text-heavy {
  font-weight: bold;
  color: #707070;
}

.api-error {
  color: #ff4b4b;
  flex-grow: 1;
  height: 100%;
}

:root {
  --accent-color: #00b3ff;
  --border-hover--dark: #909291;
  --border-hover--light: #d0d2d1;
}

@media screen and (max-width: 991px) {
  .App {
    padding: 5px;
  }
}
