.my-project .center-y > .center-y {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: white;
  margin: 0 0.5rem;
}

.my-project > :first-child {
  display: flex;
  height: 70px;
}

.project-name {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.project-name,
.project-configuration > :first-child {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}
