.gutter-sizes > div {
  display: flex;
  flex-wrap: wrap;
}

.gutter-sizes > h4 {
  margin: 0px;
  font-weight: 500;
  color: #393b3a;
  margin-bottom: 1rem;
}

.size-box,
.size-box--selected {
  border-radius: 0.25rem;
  font-weight: bold;
  font-size: 0.85rem;
  width: 120px;
  border: 2px solid #d0d2d1;
  padding: 0.4rem;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.size-box:hover {
  border-color: var(--border-hover--dark);
}

.size-box--selected {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  color: white;
}

.gutter-sizes .additional-options {
  margin: 1rem 0;
  justify-content: flex-end;
}
