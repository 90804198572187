nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
}

nav > img {
  height: 40px;
}

nav > div > svg {
  margin-inline: 0.5rem;
  width: 25px;
  height: 25px;
  color: var(--accent-color);
}

nav > div:nth-child(2) {
  color: var(--accent-color);
  font-size: 13px;
  font-weight: 700;
}

nav > div:nth-child(3) {
  color: #909291;
  font-weight: 500;
}

nav > * {
  display: flex;
  align-items: center;
}

.my-projects {
  border: 2px solid var(--accent-color);
  fill: var(--accent-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 1rem;
}

.my-projects svg {
  scale: 0.75;
}

.my-projects:hover {
  background-color: var(--accent-color);
  fill: white;
}
