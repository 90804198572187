.optional-input .checkbox,
.optional-input .checkbox--checked {
  margin-right: 0.5rem;
  scale: 0.75;
}

.optional-input > strong:first-of-type {
  margin-bottom: 0.5rem;
}

.additional-options {
  color: var(--accent-color);
  text-align: right;
  cursor: pointer;
}

.additional-options-modal > .modal-content {
  flex-direction: column;
}

.additional-options-modal > .modal-footer {
  flex-direction: row-reverse;
}

.additional-option {
  display: flex;
  padding: 0.5rem;
  border: 1px solid #eeeeee;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.additional-option > img {
  width: 55px;
  height: 55px;
  padding: 5px;
  margin: 1rem;
  object-fit: contain;
}

.additional-option > div > p {
  margin: 10px 0;
  font-size: 15px;
}
