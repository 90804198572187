.project-configuration {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 2px var(--accent-color) solid;
  border-radius: 12px;
  margin-bottom: 1rem;
}

.project-configuration > div:first-child {
  display: flex;
  align-items: center;
}

.project-configuration .project-item-name {
  flex-grow: 1;
}

.project-configuration .project-configuration .project-item-name {
  font-weight: 400;
}

.project-configuration .project-configuration {
  padding-right: 0;
  margin-bottom: 0;
}

.project-configuration .project-configuration > div:first-child {
  margin-bottom: 0;
}
