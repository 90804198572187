.size-form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.size-form > h2 > p {
  margin: 0px;
  font-weight: 400;
}

.size-form > div:first-of-type {
  display: flex;
  flex-grow: 1;
  min-height: 0px;
  overflow-y: scroll;
}

.size-form > .form-footer {
  display: flex;
  justify-content: space-between;
}

.size-form > div:first-of-type > div {
  flex-basis: 50%;
  margin: 30px;
  margin-top: 0.5rem;
}

.product-info {
  display: flex;
}

.product-info-sections > .product-info,
.product-info > div {
  flex-basis: 50%;
}

.product-info > div:last-child > img {
  max-width: 100%;
}

.size-form .optional-input {
  margin-bottom: 15px;
}

@media screen and (max-width: 991px) {
  .size-form > div:first-of-type {
    flex-direction: column;
  }

  .size-form > div:first-of-type > div:last-child {
    display: none;
  }

  .size-form .gutter-sizes > div {
    flex-direction: column;
  }

  .size-form .gutter-sizes .size-box,
  .size-form .gutter-sizes .size-box--selected {
    width: 100%;
  }

  .auto-add-msg {
    height: fit-content;
  }
}
