.product-thumbnail,
.product-thumbnail--selected {
  width: 230px;
  border: 2px solid #eeeeee;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.product-thumbnail > .thumbnail-img,
.product-thumbnail--selected > .thumbnail-img {
  width: 180px;
  height: 180px;
  padding: 5px;
  object-fit: contain;
}

.product-thumbnail > div,
.product-thumbnail--selected > div {
  flex-grow: 1;
  visibility: hidden;
  pointer-events: none;
  color: var(--accent-color);
  padding: 10px;
}

.product-thumbnail > div:hover,
.product-thumbnail--selected > div:hover {
  text-decoration: underline;
}

.product-thumbnail > svg,
.product-thumbnail--selected > svg {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.product-thumbnail:hover {
  border-color: var(--border-hover--dark);
}

.product-thumbnail:hover > *,
.product-thumbnail--selected:hover > *,
.product-thumbnail--selected > svg {
  display: block;
  visibility: visible;
  pointer-events: auto;
}

.product-thumbnail--selected {
  border-color: var(--accent-color);
}

.product-thumbnail--selected > svg {
  fill: var(--accent-color);
}
