.pendant-configurations {
  display: flex;
}

.pendant-configurations > div {
  flex-grow: 1;
  padding: 1rem;
}

.pendant-configurations span > svg {
  width: 100%;
  height: 100%;
}

.sizes-container {
  display: flex;
  flex-wrap: wrap;
}

.sizes-container > p {
  flex-basis: 100%;
}

.pendant-details {
  display: flex;
}

.image-container {
  margin-right: 1rem;
  width: 75%;
  display: flex;
  justify-content: center;
}

.image-container img {
  max-width: 100%;
  max-height: 500px;
}

.pendant-details h4 {
  font-weight: 500;
}
