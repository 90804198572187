.counter {
  display: flex;
  height: 60px;
  margin: 10px 0;
}

.counter > button {
  background-color: #e8d5c2;
  border: none;
  min-width: 60px;
  padding: 0;
}

.counter > button > svg {
  stroke-width: 2px;
}

.counter > button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.counter > button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.counter > input[type='text'] {
  border: none;
  width: 70px;
  margin: 0;
  text-align: center;
}
