.my-projects-modal {
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
}

.add-new-project {
  padding: 0.5rem 0;
  color: var(--accent-color);
  display: flex;
  align-items: center;
  font-size: 17px;
  margin-left: 1.5rem;
}

.add-new-project > svg {
  font-size: 1.75rem;
  margin-right: 0.25rem;
}

.my-projects-modal > .modal-content {
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  min-height: 0;
}

.my-projects-container {
  display: flex;
  flex-direction: column;
}

.my-projects-container svg:not(.project-code-modal *) {
  width: 40px;
  height: 40px;
  color: var(--accent-color);
  padding: 0.5rem;
}

.configurations-container {
  margin-left: 30px;
}

@media screen and (max-width: 992px) {
  .my-projects-modal {
    height: 100%;
  }
}
