.accessory-card {
  width: 300px;
  margin: 1rem;
}

.accessory-card strong {
  display: block;
  height: 60px;
  margin-bottom: 10px;
}

.accessory-card img {
  width: 160px;
  height: 160px;
  padding: 10px;
  object-fit: contain;
}

.accessory-card p {
  height: 24px;
}

.accessory-card .counter > input {
  width: 96px;
}

.accessory-card > div {
  width: 240px;
  border: 2px solid #eeeeee;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accessory-card > div:hover {
  border-color: var(--border-hover--light);
}
