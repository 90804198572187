.accessories-modal {
  height: calc(100% - 100px);
}

.accessories-container {
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
}

.accessories-modal > .modal-footer {
  justify-content: flex-end;
}
