.material-form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.material-form h2 {
  margin: 0.5rem 0;
}

.material-form > .form-footer {
  display: flex;
  justify-content: flex-end;
}

.materials-container {
  min-height: 0px;
  flex-grow: 1;
  overflow-y: auto;
}

.materials-container > div {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

.materials-container > div > div {
  width: 500px;
  margin: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  position: relative;
}

.material-name {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  height: 48px;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
}

.materials-container > div > div > p {
  height: 48px;
  margin: 0;
}

.materials-container .help {
  position: absolute !important;
  top: 0.5rem;
  right: 0.5rem;
}

.materials-container > div > div:first-child {
  background-color: #f9e4f2;
}
.materials-container > div > div:nth-child(2) {
  background-color: #fcdfb9;
}
.materials-container > div > div:nth-child(3) {
  background-color: #a6f5f7;
}
.materials-container > div > div:nth-child(4) {
  background-color: #b8ffe9;
}
.materials-container > div > div:nth-child(5) {
  background-color: #cdd4f9;
}
.materials-container > div > div:last-child {
  background-color: #c6e0de;
}

.material--unavailable {
  opacity: 0.3;
  cursor: not-allowed;
}
