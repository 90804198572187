.attachment-card {
  min-width: 420px;
  margin: 10px 40px;
}

.attachment-card > strong {
  display: block;
  margin-bottom: 10px;
}

.attachment-card > div {
  border: 2px solid #eeeeee;
  border-radius: 10px;
}

.attachment-card > div:hover {
  border-color: var(--border-hover--light);
}

.attachment-image {
  position: relative;
  padding-bottom: 0.5rem;
}

.attachment-image > img {
  width: 160px;
  height: 160px;
  padding: 10px;
  object-fit: contain;
}

.attachment-image > span {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 1rem;
  font-size: 13px;
  color: #45cb5e;
  cursor: pointer;
}

.include-lid {
  font-size: 14px;
}

.alert > .note {
  font-size: 14px;
  justify-content: center;
  margin: 0;
}
